<template>
    <ValidationObserver
        tag="form"
        ref="observer"
        @submit.prevent="onSaveSubmit(post)"
    >
        <div class="post__edit-wrap">
            <div class="post__edit-content">
                <input
                    v-if="isAdvert"
                    v-model="post.title"
                    class="post__edit-title"
                    placeholder="Тема объявления"
                    type="text"
                />

                <PostTextEditor
                    v-model="post.content"
                    :is-content="post.content"
                    :is-placeholder="`Сообщение`"
                    class="post__edit-text"
                />

                <div v-if="post.votes.length > 0 && !isAdvert" class="post__edit-voting">
                    <button
                        @click="onDeleteVotesList"
                        class="post__edit-voting__close"
                        type="button"
                        aria-label="Удалить опрос"
                        title="Удалить опрос"
                    />

                    <ValidationProvider
                        class="post__edit-voting__group post__edit-voting__group--theme"
                        tag="div"
                        mode="eager"
                        v-slot="{ errors }"
                        :rules="`required|min_length:5|max_length:50`"
                    >
                        <label
                            class="post__edit-voting__label"
                            for="vote_title"
                        >
                            Тема опроса
                        </label>

                        <v-textArea
                            :id="`vote_title`"
                            :name="`theme`"
                            :max-length="50"
                            :value="post.title_poll"
                            @input="value => post.title_poll = value"
                        />

                        <span
                            v-if="errors.length"
                            class="v-error animated fadeInDown"
                        >
                            {{errors[0]}}
                        </span>
                    </ValidationProvider>

                    <div class="post__edit-voting__list">
                        <div
                            v-for="(vote, index) in post.votes"
                            :key="`post-${post.id}-votes_${index}`"
                            class="post__edit-voting__item"
                        >
                            <ValidationProvider
                                class="post__edit-voting__group"
                                tag="div"
                                mode="eager"
                                v-slot="{errors}"
                                :vid="`post-${post.id}-vote-${index}`"
                                :rules="`required|min_length:2|max_length:50`"
                            >
                                <label
                                    :for="`post-${post.id}-vote-${index}`"
                                    class="post__edit-voting__label"
                                >
                                    {{ index + 1 }} вариант ответа
                                </label>

                                <div class="post__edit-voting__wrap">
                                    <v-textArea
                                        :id="`post-${post.id}-vote-${index}`"
                                        :name="`post-${post.id}-vote-${index}`"
                                        :max-length="50"
                                        :value="vote.text"
                                        @input="value => vote.text = value"
                                    />

                                    <i
                                        v-if="post.votes.length > 2"
                                        class="post__edit-voting__item-del"
                                        href="javascript:void(0)"
                                        title="Удалить вариант ответа"
                                        aria-label="Удалить вариант ответа"
                                        @click="onDeleteVote(index)"
                                    />
                                </div>

                                <span class="v-error animated fadeInDown" v-if="errors.length">
                                    {{errors[0]}}
                                </span>
                            </ValidationProvider>
                            <!-- Удалить вариант ответа -->
                        </div>

                        <a
                            v-if="post.votes.length < 10"
                            class="post__edit-voting__add"
                            href="javascript:void(0)"
                            @click="onAddVote"
                        >+ Добавить вариант ответа</a>
                    </div>
                </div>

                <ul
                    v-if="post.images.length"
                    class="post__edit-images"
                >
                    <li
                        v-for="image in post.images"
                        :style="{ backgroundImage: `url(${image.src || image.file})` }"
                        class="post__edit-image"
                    >
                        <i
                            class="post__edit-image-delete"
                            @click="onDeleteImg(image.id)"
                        />
                    </li>
                </ul>
            </div>

            <!-- Проверка :disabled для того, чтобы проверялась заполненность полей в зависимости от типа поста -->
            <div class="post__edit-footer">
                <div class="post__edit-footer__row">
                    <div class="post__edit-footer__col">
                        <div class="post__edit-btns">
                            <button
                                class="v-btn v-btn--transparent post__cancel-btn"
                                @click="$emit('cancel', false)"
                            >
                                Отменить
                            </button>

                            <button
                                class="v-btn post__edit-save"
                                type="submit"
                                :disabled="btnValidate"
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>

                    <div class="post__edit-footer__col">
                        <a
                            v-if="post.votes.length === 0"
                            title="Опрос"
                            href="javascript:void(0)"
                            class="post__edit-attach__item post__edit-attach__item--vote"
                            @click="onAddVote"
                        >
                            <icon
                                width="18"
                                height="18"
                                icon-color="currentColor"
                                class="post__edit-attach__icon"
                            >
                                <path d="M16 1a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H3V1h13ZM9 10a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H3v-7h6ZM2 18V0H0v18h2Z"/>
                            </icon>
                        </a>

                        <label
                            :for="`id_attach-img-${postId}`"
                            title="Изображение"
                            class="post__edit-attach__item post__edit-attach__item--img"
                        >
                            <icon
                                width="18"
                                height="18"
                                icon-color="currentColor"
                                class="post__edit-attach__icon"
                            >
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M16 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm-3.707 8.707L5.5 10 9 12l7-7.5V15a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1.086a1 1 0 0 1 .293-.707Z"/>
                            </icon>

                            <input
                                type="file"
                                ref="preview"
                                name="attach-img"
                                hidden
                                :id="`id_attach-img-${postId}`"
                                @change="onAddImgHandler"
                            >
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </ValidationObserver>
</template>

<script>
import { mapState } from 'vuex';
import session from '@/api/session';
import Icon from '@/components/icon/Icon';
import PostTextEditor from '@/components/wall/Post/PostTextEditor';

export default {
    name: 'EditPost',
    components: {
        Icon,
        PostTextEditor,
        vTextArea: () => import('@/components/TextArea')
    },
    props: {
        data: {
            type: Object,
            required: true
        },
        isAdvert: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            postId: this._uid,
            post: { ...JSON.parse(JSON.stringify(this.data)) }
        };
    },
    computed: {
        ...mapState('default_data', [
            'limits',
            'domain'
        ]),

        btnValidate() {
            const titleLength = this.post.title.length;
            const textLength = this.post.content.length;
            const { MIN_CHARS } = this.limits;

            if (this.isAdvert) {
                return titleLength < MIN_CHARS || textLength < MIN_CHARS;
            } else {
                return textLength < MIN_CHARS;
            }
        }
    },

    methods: {
        onDeleteImg(id) {
            this.post.images = this.post.images.filter(post => id !== post.id);
        },

        async onAddImgHandler(e) {
            const file = e.target.files[0];
            const ALLOWED_TYPES = /\.(jpe?g|png)$/i;
            const idImage = this.post.images.length ? (this.post.images[this.post.images.length - 1].id + 1) : 1;

            if (!ALLOWED_TYPES.test(file.name)) {
                this.toastError('Допустимые форматы: png, jpg, jpeg');
                return false;
            } else if (file.size > 5000000) {
                this.toastError('Максимальный размер изображения: 5мб');
                return false;
            } else if (this.post.images.length > 10) {
                this.toastError('Максимум изображений: 10');
                return false;
            } else {
                const dataFile = {
                    file_name: file.name,
                    domain_name: this.domain.subdomain
                };

                await session.post('/api/v1/s3/', dataFile)
                    .then(async (request) => {
                        const data = request.data;
                        const dataImg = {
                            id: idImage,
                            name: file.name,
                            file: data['public-link'],
                            author: null,
                            created_at: this.$moment(new Date()).format('MM/DD/YYYY HH:mm:ss'),
                            wall: this.post.id
                        };

                        await session.put(`${data['upload-link']}`, file);
                        this.post.images.push(dataImg);
                    })
                    .catch(() => {
                        this.$swal({
                            title: 'Ошибка!',
                            text: 'При загрузки изображения произошла ошибка. Попробуйте ещё раз.',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 2400
                        });
                    });
            }

            // Очистка значения у поля для того, чтобы можно было загружать один и тот же файл подряд
            e.target.value = null;
        },

        async onSaveSubmit(post) {
            const isValid = await this.$refs.observer.validate();

            if (isValid) {
                this.$emit('save', post);
            }
        },

        onAddVote() {
            this.post.votes.push({
                text: '',
                is_voted: false,
                clicked: {
                    count: 0,
                    list: []
                }
            });
        },

        onDeleteVote(index1) {
            this.post.votes = this.post.votes.filter((vote, index) => index1 !== index);
        },

        onDeleteVotesList() {
            this.post.votes = [];
            this.post.title_poll = '';
        },

        toastError(text, timer = 3000) {
            this.$swal.fire({
                toast: true,
                position: 'top-end',
                title: text,
                icon: 'error',
                showConfirmButton: false,
                timer: timer,
                timerProgressBar: true,
                onOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer);
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer);
                }
            });
        }
    }
};
</script>

<style lang="sass">
@import '#sass/_variables.sass'
@import '#sass/v-style'

.post__edit
    resize: none
    outline: none
    width: 100%
    padding: 5px 10px
    margin-bottom: 10px
    border: 1px solid rgba(0,0,0, .2)
    &-text,
    &-title
        outline: none
        width: 100%
        padding: 10px 15px
        border: 1px solid rgba(0,0,0, .2)
    &-title
        border-bottom: none
        font-weight: 600
    &-content
        display: flex
        flex-direction: column
    &-images
        display: flex
        width: 100%
        margin-top: 25px
        overflow-x: auto
    &-image
        height: 80px
        width: 80px
        min-height: 80px
        min-width: 80px
        background-color: $black-2
        background-repeat: no-repeat
        background-size: cover
        position: relative
        &:nth-child(n+2)
            margin-left: 15px
        &-delete
            height: 16px
            width: 16px
            position: absolute
            top: 0
            right: 0
            background-color: rgba(0, 0, 0, .7)
            background-image: $times-icon
            background-repeat: no-repeat
            background-position: center
            z-index: 2
            cursor: pointer
    &-btns
        display: flex
    &-footer
        margin-top: 20px
        &__row
            margin: -5px
            display: flex
            flex-wrap: wrap
            align-items: center
            justify-content: space-between
        &__col
            margin: 5px
            flex-grow: 1
            display: flex
            align-items: center
            &:last-of-type
                justify-content: flex-end
    &-voting
        padding: 30px
        position: relative
        margin: 30px -30px 0px
        border-style: solid
        border-color: #dbdbdb
        border-width: 1px 0px 1px
        &__wrap
            display: flex
            align-items: center
            justify-content: space-between
            width: 100%
        &__close
            background-image: $plus-icon
            background-repeat: no-repeat
            background-position: center
            background-size: contain
            height: 15px
            width: 15px
            transform: rotate(45deg)
            position: absolute
            right: 20px
            top: 20px
            cursor: pointer
            border: 0px
            background-color: transparent
        &__group
            position: relative
            display: flex
            flex-direction: column
            &:not(.new-post__voting__group--theme)
                width: 90%
                padding-right: 25px
                @media screen and (max-width: 768px)
                    width: 100%
            .textarea
                &__field
                    min-height: 44px
                    border: 1px solid #DBDBDB
        &__label
            color: #aaa
            font-weight: normal
        &__input
            border: 1px solid #DBDBDB
            outline: none
            height: 40px
            padding-left: 15px
            width: 100%
            &--title
                width: 75%
        &__item
            display: flex
            align-items: center
            width: 100%
            position: relative
            &:nth-child(n+2)
                margin-top: 10px
            &-del
                top: 0
                right: 0
                bottom: 0
                position: absolute
                height: 15px
                width: 15px
                margin: auto 0
                cursor: pointer
                background-image: $plus-icon
                background-repeat: no-repeat
                background-position: center
                background-size: contain
                transform: rotate(45deg)
        &__list
            margin-top: 20px
        &__add
            display: flex
            align-items: center
            justify-content: center
            width: 100%
            border: 1px dashed #DBDBDB
            border-radius: 4px
            height: 40px
            color: #000
            font-size: 1rem
            margin-top: 30px
    &-attach
        $ap: &

        display: flex
        justify-content: flex-end
        width: 100%
        margin-top: 20px
        &__item
            margin: 0px
            cursor: pointer
            position: relative
            color: #dbdee9
            & + #{$ap}__item
                margin-left: 20px
            &:hover
                color: $gold
</style>
